import React from 'react';
import { isEven } from '../../helpers';

const RadioButtons = ({
	selectedValue = false,
	options = [],
	className = false,
	inputName = '',
	rules = false,
	onChange = null,
	onClick = null,
	saveResponse = true,
	validateAPI = false
}) => {
	const createClassList = () => {
		let classList = 'ca-radio-buttons';
		if (className) classList = classList + ' ca-radio-buttons--' + className;
		if (isEven(options.length)) classList = classList + ' ca-radio-buttons--even';
		return classList;
	};

	const setupOptionRules = (rules, option) => {
		let optionRules = '';
		Object.keys(rules).forEach((key, index) => {
			if (key === option) {
				optionRules = rules[key].skip;
			}
		});
		return optionRules;
	};

	const radioButtons = options.map((option, index) => (
		<span className='ca-radio-buttons__option' key={index}>
			<input
				type='radio'
				name={inputName}
				id={inputName + index}
				value={option.value}
				onChange={onChange}
				data-save={saveResponse}
				data-validate-api={validateAPI}
			/>
			<label
				id={option.id}
				className={selectedValue && selectedValue === option.value ? 'ca-radio-buttons--selected' : null}
				htmlFor={inputName + index}
				data-rules={rules ? setupOptionRules(rules, option.value) : null}
				onClick={onClick}
			>
				{option.label ? option.label : option.value}
			</label>
		</span>
	));

	return <div className={createClassList()}>{radioButtons}</div>;
};

export default RadioButtons;

{
	/* <RadioButtons
    className="test"
    options={item.options}
    inputName={item.form.name}
    selectedValue={item.selected}
    rules={item.rules}
    onChange={this.handleOptionChange}
    onClick={getRules}
/> */
}
