import React from "react"
import Title from "../atoms/title"
import RadioButtons from '../atoms/radio-buttons'
import TextInputField from '../atoms/text-input-field'
import SliderField from '../atoms/slider-field'
import SelectField from '../atoms/select-field'

const QuestionnaireStep = ({
    step = 1,
    id= 'step-1',
    classNames = '',
    title = '',
    subTitle = false,
    sliderValue = 0,
    body = [],
    disclaimer = false,
    rules = null,
    onChange = null
}) => {

    const createClassList = () => {
        let classList = 'cm-questionnaire-step';
        if(classNames) classList = classList + ' ' + classNames;
        return classList;
    }

    const titleComponent = (title) ? (
        <Title
            type='h3'
            title={title}
            align='center'
            color='dark'
        />
    ) : null;

    const subTitleComponent = (subTitle) ? ( 
        <p dangerouslySetInnerHTML={{ __html: subTitle }} />
    ) : null;

    const headerComponent = (
        <div className='cm-questionnaire-step__header'>
            {titleComponent}
            {subTitleComponent}
        </div>
    )

    // const updateParentWithDisclaimer = () => {

    // }

    // const updateParentWithDisclaimer = (disclaimer) ? (this.props.) 


    const setupBodyComponent = () => {
        let items = [];

        body.map((item, index) => {
            if(item.type === 'text' || item.type === 'email') {
                items.push (
                    <TextInputField
                        key={index}
                        classNameJS="js-show--button"
                        isRequired={item.form.required}
                        isAutoComplete={item.form.autoComplete}
                        buttonID={item.form.buttonID}
                        inputType={item.type}
                        inputName={item.form.name}
                        pattern={item.form.pattern}
                        placeholder={item.placeholder}
                        errorMsg={item.form.errorMessage}
                        onChange={onChange}
                        valueType={item.form.valueType}
                        saveResponse={item.form.submitAPI}
                        validateAPI={item.form.validateAPI}
                    />
                )
            }
            if(item.type === 'radio') {
                items.push(
                    <RadioButtons
                        key={index}
                        options={item.options}
                        inputName={item.form.name}
                        selectedValue={item.selected}
                        rules={item.rules}
                        keyIndex={index}
                        onChange={onChange}
                        onClick={rules}
                        saveResponse={item.form.submitAPI}
                        validateAPI={item.form.validateAPI}
                    />
                )
            }
            if(item.type === 'slider') {
                items.push(
                    <SliderField
                        key={index}
                        classNameJS="js-show--button"
                        isRequired={item.form.required}
                        errorMsg={item.form.errorMessage}
                        buttonID={item.form.buttonID}
                        sliderName={item.form.name}
                        sliderValue={(sliderValue === 0) ? item.sliderOptions.defaultValue : sliderValue}
                        defaultValue={item.sliderOptions.defaultValue}
                        min={item.sliderOptions.min}
                        max={item.sliderOptions.max}
                        step={item.sliderOptions.step}
                        onChange={onChange}
                        saveResponse={item.form.submitAPI}
                        validateAPI={item.form.validateAPI}
                    />
                )
            }
            if(item.type === 'select') {
                items.push(
                    <SelectField
                        key={index}
                        classNameJS="js-show--button"
                        isRequired={item.form.required}
                        options={item.options}
                        buttonID={item.form.buttonID}
                        selectName={item.form.name}
                        errorMsg={item.form.errorMessage}
                        onChange={onChange}
                        valueType={item.form.valueType}
                        saveResponse={item.form.submitAPI}
                        validateAPI={item.form.validateAPI}
                    />
                )
            }
        });

        return (
            <div 
            className='cm-questionnaire-step__body'>
                {items}
            </div>
        )
    }

    const disclaimerComponent = (disclaimer && disclaimer.aboveButton) ? (
        <div className='cm-questionnaire-step__disclaimer cm-questionnaire-step__disclaimer--step'>
            <p>{disclaimer.aboveButton}</p>
        </div>
    ) : null;

    return (
        <div 
            data-step={step} 
            id={id} 
            className={createClassList()}
            key={step}>
                {headerComponent}
                {setupBodyComponent()}
                {/* {disclaimerComponent} */}
        </div>
    );
}

export default QuestionnaireStep;

{/* <QuestionnaireStep
    step=0
    id=''
    classNames=''
    title=''
    subTitle=''
    body=[]
    disclaimer=''
    rules=[]
    onChange=null
/> */}