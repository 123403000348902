import $ from 'jquery';

/*******************************************************
 * isObjEmpty
 * returns boolean
 * checks if object passed is empty
 *******************************************************/
export function isObjEmpty(obj) {
    return Object.keys(obj).length === 0;
}

/*******************************************************
 * grabResponseParam
 * returns an obj
 * checks the URL for the response parameter 
 * and passes the response in an obj
 * resp parameter comes from Questionnaire Teaser
 *******************************************************/
export function grabResponseParam() {
	const getParamValue = (parameter) => {
		for (let i = 0; i < urlVariables.length; i++) {
			let parameterName = urlVariables[i].split('=');
			if (parameterName[0] === parameter) {
				return parameterName[1] === null ? false : parameterName[1];
			}
		}
		return false;
	}
	
	let urlQuery = decodeURIComponent(window.location.search.substring(1));
	let urlVariables = urlQuery.split('&');

	let paramValue = getParamValue('resp');

	if(!paramValue) return false;

	let paramValueDecoded = window.atob(paramValue);

	let responseVariables = paramValueDecoded.split('=');
	let responseObj = {};

	if (responseVariables.length) {
		responseObj[responseVariables[0]] = responseVariables[1]
	};

	return responseObj;
}

/*******************************************************
 * grabUTMparams
 * returns an obj
 * checks the URL for any UTM parameters 
 * and passes them in an obj
 *******************************************************/
export function grabUTMparams() {
	const getUTMValue = (parameter) => {
		for (let i = 0; i < utmVariables.length; i++) {
			let parameterName = utmVariables[i].split('=');
			if (parameterName[0] === parameter) {
				return parameterName[1] === null ? null : parameterName[1];
			}
		}
	}
	
	const valueExists = (value) => {
		return (value != null && value != '' && value != undefined)
	}

	let utmQuery = decodeURIComponent(window.location.search.substring(1));
	let utmVariables = utmQuery.split('&');
	let utmObj = {};

	const utmParams = [
		'utm_source',
		'utm_medium',
		'utm_campaign',
		'utm_content',
		'utm_term'
	];

	utmParams.forEach(param => {
		let paramValue = getUTMValue(param);
		
		if (valueExists(paramValue)) {
			utmObj[param] = paramValue
		};
	});

	return utmObj;
}

/*******************************************************
 * regExValidation
 * returns boolean
 * validates the value against valueType regex pattern
 *******************************************************/
export function regExValidation(valueType, value) {
	switch (valueType) {
		// case 'date':
		// 	return isValidDate(value, 'MM-DD-YYYY');
		case 'streetAddress':
			return /\d+\w+\s\w+\s\w+/.test(value);
		case 'city':
			return /^[A-Za-z.,\-–]+(\s[A-Za-z.’']+)?(\s[A-Za-z]+)?$/.test(value);
		case 'state':
		case 'select':
			return /.*\S.*/.test(value);
		case 'zipCode':
			return /(^\d{5}-?\d{4}$)|(^\d{5}$)/.test(value);
		case 'phoneNumber':
			return /(^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$)/.test(value);
		case 'email':
			return /(^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$)/.test(value);
		case 'companyName':
			return /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9 '-?!.]{2,50}$/.test(value);
		case 'firstName':
		case 'lastName':
			return /^([^0-9]{2,50})$/.test(value);
		default:
			return true;
	}
}

/*******************************************************
 * formatPhoneText
 * returns a string that is in XXX-XXX-XXXX format
 *******************************************************/
export function formatPhoneText(value) {
	let valueTrimmed = value.trim().replaceAll('-', '');

	if (!isNumeric(valueTrimmed)) return '';
	if (valueTrimmed.length > 10) {
		if (valueTrimmed.length === 12 && valueTrimmed.substring(0, 2) === '+1') {
			valueTrimmed = valueTrimmed.substring(2);
		} else {
			return value.substring(0, 12);
		}
	}

	if (valueTrimmed.length > 3 && valueTrimmed.length <= 6)
		valueTrimmed = valueTrimmed.slice(0, 3) + '-' + valueTrimmed.slice(3);
	else if (valueTrimmed.length > 6)
		valueTrimmed = valueTrimmed.slice(0, 3) + '-' + valueTrimmed.slice(3, 6) + '-' + valueTrimmed.slice(6);

	function isNumeric(num) {
		return !isNaN(num);
	}

	return valueTrimmed;
}

/*******************************************************
 * formatDate
 * returns a string that is in MM-DD-YYYY format
 *******************************************************/
export function formatDate(value) {
	console.log('inside formatDate()');
	console.log('value: ', value);
	let valueTrimmed = value.trim().replaceAll('-', '');

	if (!isNumeric(valueTrimmed)) return '';
	if (valueTrimmed.length > 8) return value.substring(0, 10);

	if (valueTrimmed.length > 2 && valueTrimmed.length <= 4) {
		valueTrimmed = valueTrimmed.slice(0, 2) + '-' + valueTrimmed.slice(2);
		console.log('inside formatDate() inside if');
	} else if (valueTrimmed.length > 4) {
		valueTrimmed = valueTrimmed.slice(0, 2) + '-' + valueTrimmed.slice(2, 4) + '-' + valueTrimmed.slice(4);
		console.log('inside formatDate() inside else');
	}

	function isNumeric(num) {
		return !isNaN(num);
	}
	console.log('valueTrimmed: ', valueTrimmed);

	return valueTrimmed;
}

/*******************************************************
 * formatDateAPI
 * returns a string that is in YYYY-MM-DD format
 *******************************************************/
export function formatDateAPI(value) {
	let valueTrimmed = value.trim().replaceAll('-', '/');
	let date = new Date(valueTrimmed);
	let formattedDate =
		date.getFullYear() + '-' + appendLeadingZeroes(date.getMonth() + 1) + '-' + appendLeadingZeroes(date.getDate());
	return formattedDate;
}

/*******************************************************
 * formatZipCode
 * returns a string that is in XXXXX or XXXXX-XXXX format
 *******************************************************/
export function formatZipCode(value) {
	let valueTrimmed = value.trim().replaceAll('-', '');

	if (!isNumeric(valueTrimmed)) return '';
	if (valueTrimmed.length > 9) return value.substring(0, 10);

	if (valueTrimmed.length > 5) valueTrimmed = valueTrimmed.slice(0, 5) + '-' + valueTrimmed.slice(5);

	return valueTrimmed;
}

/*******************************************************
 * isValidDate
 * returns boolean
 * validates that the input string is a valid date
 *******************************************************/
export function isValidDate(value, format = 'MM-DD-YYYY') {
	let parts = value.split('-');
	let month, day, year;

	if (format === 'MM-DD-YYYY') {
		// First check for the pattern
		if (!/^\d{2}-\d{2}-\d{4}$/.test(value)) return false;
		// Parse the date parts to integers
		month = parseInt(parts[0], 10);
		day = parseInt(parts[1], 10);
		year = parseInt(parts[2], 10);
	} else if (format === 'YYYY-MM-DD') {
		// First check for the pattern
		if (!/^\d{4}-\d{2}-\d{2}$/.test(value)) return false;
		// Parse the date parts to integers
		year = parseInt(parts[0], 10);
		month = parseInt(parts[1], 10);
		day = parseInt(parts[2], 10);
	} else if (format === 'MM-YYYY') {
		// First check for the pattern
		if (!/^\d{2}-\d{4}$/.test(value)) return false;
		// Parse the date parts to integers
		month = parseInt(parts[0], 10);
		day = parseInt('1', 10);
		year = parseInt(parts[1], 10);
	}

	let currentYear = new Date().getFullYear();
	let maxYear = currentYear - 150;
	let minYear = currentYear - 15;

	// Check the ranges of month and year
	if (year < maxYear || year > minYear || month == 0 || month > 12) return false;

	let monthLength = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

	// Check the range of the day
	return day > 0 && day <= monthLength[month - 1];
}

/*******************************************************
 * appendLeadingZeroes
 * returns param n with a 0 ahead if 1 digit in length
 *******************************************************/
export function appendLeadingZeroes(n) {
	if (n <= 9) {
		return '0' + n;
	}
	return n;
}

/*******************************************************
 * isEven
 * returns boolean
 * checks if the parameter is even
 *******************************************************/
export function isEven(n) {
	return n % 2 == 0;
}

/*******************************************************
 * isNumeric
 * returns boolean
 * checks if the parameter is a number
 *******************************************************/
export function isNumeric(num) {
	return !isNaN(num);
}

/*******************************************************
 * isMobile
 * returns boolean
 * checks if user is on a mobile device
 * from http://detectmobilebrowsers.com/
 *******************************************************/
export function isMobile() {
	let check = false;
	(function(a) {
		if (
			/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
				a
			) ||
			/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
				a.substr(0, 4)
			)
		)
			check = true;
	})(navigator.userAgent || navigator.vendor || window.opera);
	return check;
}

/*******************************************************
 * isMobileOrTablet
 * returns boolean
 * checks if user is on a mobile device or tablet
 * from http://detectmobilebrowsers.com/
 *******************************************************/
export function isMobileOrTablet() {
	let check = false;
	(function(a) {
		if (
			/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
				a
			) ||
			/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
				a.substr(0, 4)
			)
		)
			check = true;
	})(navigator.userAgent || navigator.vendor || window.opera);
	return check;
}

/*******************************************************
 * scrollToTop
 * when called scrolls page to the top
 *******************************************************/
export function scrollToTop() {
	$('html, body').animate(
		{
			scrollTop: 0,
		},
		250
	);
}
